<template>
  <view>
    用户分析
  </view>
</template>

<script>
export default {
  name: "brandUserAnalysis"
}
</script>

<style scoped>

</style>